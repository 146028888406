import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { ExamRegistryModel } from "../models/exams-registry.model";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";
import { Observable, catchError, tap } from "rxjs";
import { SchoolExamsModel } from "../models/school-exams.model";

@Injectable({
  providedIn: "root",
})
export class ExamRegistryService extends BaseService<ExamRegistryModel> {
  override baseUrl: string = `${this.baseUrl}/exam-registry`;

  public getExamByClassAndSubject(
    classId: string,
    subjectId: string
  ): Observable<ApiResponse<PaginationData<ExamRegistryModel>>> {
    const url = `${this.baseUrl}?class_id=${classId}&subject_id=${subjectId}`;
    return this.api
      .get<ApiResponse<PaginationData<ExamRegistryModel>>>(url)
      .pipe(
        tap((_) => this.log("fetched Entity")),
        catchError(this.handleError(`fetch`))
      );
  }

  getNextSchoolExamId(
    schoolExams: SchoolExamsModel[],
    examRegistry: ExamRegistryModel[]
  ): SchoolExamsModel{
    if (examRegistry.length === 0) {
      const firstExam = schoolExams.reduce((smallest, current) => {
        return current.position! < smallest.position! ? current : smallest;
      }, schoolExams[0]);
      return firstExam;
    } else {
      const nextExam = this.getNthItem(examRegistry.length,schoolExams)
      // console.log(this.getNthItem(examRegistry.length, schoolExams));
      return nextExam
    }
  }

  private getNthItem(n: number, avalList: SchoolExamsModel[]): SchoolExamsModel {
    if (n < 0 || n >= avalList.length) {
      // console.log("Invalid input: index out of range"); 
    }

    // Sort the array based on the 'id' property in ascending order
    //const sortedObjects = avalList.sort((a, b) => a.id! - b.id!);

    return avalList.find((item) => item.position === n+1)!;
  }

  //ToDo atualizar as rotas para quando for exportar o boletim de fato
  public getExams2Export(
    classId: string,
    schoolYear: string
  ): Observable<ApiResponse<PaginationData<any>>> {
    const url = `${this.baseUrl}?class_id=${classId}&schoolYear=${schoolYear}`;
    return this.api.get<ApiResponse<PaginationData<any>>>(url).pipe(
      tap((_) => this.log("fetched Entity")),
      catchError(this.handleError("export csv"))
    );
  }
}
