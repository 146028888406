import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, ViewChild } from "@angular/core";
import { GradeService } from "../../../shared/services/grade.service";
import { Grade } from "../../../shared/models/grade.model";
import { MatTableDataSource } from "@angular/material/table";
import { PerformanceUtils } from "../../../shared/performance-utils/performance-utils.service";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { Subject } from "../../../shared/models/subject.model";
import { DisciplineBaseService } from "../../../shared/services/discipline-base.service";
import { SubjectBase } from "../../../shared/models/subject-base.model";
import { LoggingService } from "src/app/core/services/logging.service";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";
import { MatSort } from "@angular/material/sort";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { BreadcrumbService } from "xng-breadcrumb";
import { FormBuilder } from "@angular/forms";
import { DeleteDialogComponent } from "../../../shared/components/delete-dialog/delete-dialog.component";
import { MatDialog } from "@angular/material/dialog";

export interface ClassInfo {
  subject: string;
  teacher: string;
  class: string;
}

export interface subjectBase {
  id: number;
  subjectBaseTitle: string;
}

export interface SubjectInfo extends Subject {
  subject_base_name: string;
  teacher: string;
  class: string;
}

const bncc_base_subject: subjectBase[] = [
  { id: 1, subjectBaseTitle: "Matemática" },
  { id: 2, subjectBaseTitle: "Ciências da Natureza" },
  { id: 3, subjectBaseTitle: "Ciências Humanas e Sociais Aplicadas" },
  { id: 4, subjectBaseTitle: "Linguagens e suas Tecnologias" },
];

const ELEMENT_DATA: ClassInfo[] = [
  { subject: "Mathematics", teacher: "John Doe", class: "101A" },
  { subject: "Physics", teacher: "Jane Smith", class: "102B" },
  { subject: "Chemistry", teacher: "Alice Johnson", class: "103C" },
  { subject: "Biology", teacher: "Mike Brown", class: "104D" },
  { subject: "History", teacher: "Sarah Wilson", class: "105E" },
  { subject: "English", teacher: "Emma Davis", class: "106F" },
  { subject: "Art", teacher: "William Garcia", class: "107G" },
  { subject: "Music", teacher: "Sophia Martinez", class: "108H" },
  { subject: "Physical Education", teacher: "James Rodriguez", class: "109I" },
  { subject: "Computer Science", teacher: "Isabella Hernandez", class: "110J" },
];

@Component({
  selector: "app-read-disciplina",
  templateUrl: "./read-disciplina.component.html",
  styleUrls: ["./read-disciplina.component.sass"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ReadDisciplinaComponent {
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource: any;
  dataSourceNotDefault: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  activeFilter: string | null = null;

  columnsToDisplay = ["title", "weeklyHours", "teacher", "class", "actions"];
  columnsToDisplayName = [
    "Disciplina",
    "Carga Horária",
    "Professor",
    "Turma",
    "Ações",
  ];
  expandedElement!: ClassInfo | null;
  gradesBySchool!: Grade[];
  disciplineBase!: SubjectBase[];
  disciplinesInfo!: SubjectInfo[];
  isTableVisible: boolean = false;
  constructor(
    private logService: LoggingService,
    private gradeService: GradeService,
    private subjectService: DisciplineService,
    private subjectBaseService: DisciplineBaseService,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {}

  searchForm = this.formBuilder.group({
    search: "",
  });

  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Disciplina");
    this.subjectBaseService.getAllWoutPagination().subscribe({
      next: (apiData) => {
        this.disciplineBase = apiData.data!;
      },
      error: (error) => {
        this.logService.error(`error in fetch SubjectBase`, error);
      },
    });
    this.gradeService.getGrades().subscribe({
      next: (data: Grade[]) => {
        // console.log(data);
        this.gradesBySchool = data;
        this.loadSubjectToDataTable();
      },
      complete: () => {
        this.isTableVisible = true;
      },
    });
  }

  loadSubjectToDataTable(gradeId?: any) {
    let defaultGradeId = 0;
    let actualGrade = this.gradesBySchool[defaultGradeId].id!;
    if (gradeId) actualGrade = gradeId;

    this.subjectService.getAllSubjects().subscribe({
      next: (data: any) => {
        this.disciplinesInfo = data.data!.data!;
        this.dataSource = new MatTableDataSource(this.disciplinesInfo);
        this.dataSource.data = this.dataSource.data.filter(
          (item: any) => item.grade_id == actualGrade && item.isDefault == true
        );
        this.dataSourceNotDefault = new MatTableDataSource(
          this.disciplinesInfo
        ).data.filter(
          (item: any) => item.grade_id == actualGrade && item.isDefault == false
        );
      },
    });
  }

  getTeacherNames(element: { user_subject_class: any[] }) {
    if (
      !element.user_subject_class ||
      element.user_subject_class.length === 0
    ) {
      return "Disciplina sem professor";
    }

    const names = [
      ...new Set(
        element.user_subject_class
          .map((usc) => (usc.user && usc.user.name ? usc.user.name : null))
          .filter((name) => name !== null)
      ),
    ];

    return names.length > 0 ? names.join(", ") : "Disciplina sem professor";
  }

  getWeeklyHours(element: { weekly_hours: number }) {
    // console.log(element);
    return element.weekly_hours;
  }

  getClassNames(element: { user_subject_class: any[] }) {
    if (
      !element.user_subject_class ||
      element.user_subject_class.length === 0
    ) {
      return "Disciplina sem turma";
    }

    const titles = [
      ...new Set(
        element.user_subject_class
          .map((usc) => (usc.class && usc.class.title ? usc.class.title : null))
          .filter((title) => title !== null)
      ),
    ];

    return titles.length > 0 ? titles.join(", ") : "Disciplina sem turma";
  }

  getDataSourceForTab(gradeId: string) {
    let tabDataSource = this.disciplinesInfo.filter(
      (obj) => obj.grade_id == gradeId
    );
    return new MatTableDataSource(tabDataSource);
  }
  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.applyGradeFilter(this.gradesBySchool[event.index ?? 0].id!);
  }

  applyGradeFilter(gradeId: string) {
    this.clearFilter();
    this.dataSource.data = this.dataSource.data.filter(
      (item: any) => item.grade_id === gradeId && item.isDefault == true
    );
    this.dataSourceNotDefault.data = this.dataSourceNotDefault.data.filter(
      (item: any) => item.grade_id === gradeId && item.isDefault == false
    );
    this.activeFilter = gradeId;
  }

  clearFilter() {
    this.dataSource = new MatTableDataSource(this.disciplinesInfo);
    this.dataSourceNotDefault = new MatTableDataSource(this.disciplinesInfo);
    this.activeFilter = null;
  }

  applyFilter(event: Event): void {
    this.dataSource.filter = this.searchForm.value.search?.trim().toLowerCase();
    this.dataSourceNotDefault.filter = this.searchForm.value.search
      ?.trim()
      .toLowerCase();
  }

  deleteSubject(subject: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: { message: "Você tem certeza que deseja deletar essa disciplina?" },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.subjectService.delete(subject.id).subscribe({
          next: (apiData) => {
            //console.log(apiData);
          },
          complete: () => {
            this.loadSubjectToDataTable(subject.grade_id);
          },
        });
      } else {
        // console.log("Deletion canceled.");
      }
    });
  }
}
