import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreadcrumbService } from "xng-breadcrumb";
import { TurmaService } from "../../../shared/services/turma.service";
import { Turma } from "../../../shared/models/turma.model";
import { GradeService } from "../../../shared/services/grade.service";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { School } from "../../../shared/models/school.model";
import { Grade } from "../../../shared/models/grade.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";
import { DisciplineService } from "../../../shared/services/discipline.service";

@Component({
  selector: "app-create-turmas",
  templateUrl: "./create-turmas.component.html",
  styleUrls: ["./create-turmas.component.sass"],
})
export class CreateTurmasComponent {
  createTurmasForm!: FormGroup;
  isLoading: boolean = false;
  defaultSchool: School = {
    id: '0',
    title: "",
    created_at: "",
    updated_at: "",
  };
  gradesBySchool!: Grade[];
  yearsRange!: Number[];
  isFormSynced: boolean = false;

  constructor(
    private turmaService: TurmaService,
    private gradeService: GradeService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    let schoolId: string;
    this.breadcrumbService.set("@create", "Criar Turma");
    this.yearsRange = this.generateYearList();
    this.gradeService.getGrades().subscribe({
      next: (data) => {
        this.gradesBySchool = data;
      },
    });
    this.simulateFormSync();
    this.createTurmasForm = this.fb.group({
      turmaName: ["", Validators.required],
      description: ["sem descrição"],
      selectorTurmaSerie: [null, Validators.required],
      selectorAnoField: [this.yearsRange[1], Validators.required],
      selectorShiftField: ["", Validators.required],
    });
  }

  simulateFormSync() {
    setTimeout(() => (this.isFormSynced = true), 3000); // Simulates a 3-second sync
  }

  generateYearList() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1, currentYear + 2];
  }

  onSubmit() {
    this.isLoading = true;
    // Handle form submission logic here
    const newTurma: Turma = {
      title: this.createTurmasForm.get("turmaName")!.value,
      description: this.createTurmasForm.get("description")!.value,
      shift: this.createTurmasForm.get("selectorShiftField")!.value,
      activity_year: this.createTurmasForm.get("selectorAnoField")!.value,
      grade_id: this.createTurmasForm.get("selectorTurmaSerie")!.value,
    };
    //console.log(newTurma);
    this.turmaService.save(newTurma).subscribe({
      next: (apiResponse: ApiResponse<Turma>) => {
        // console.log(apiResponse?.data);
        this.isLoading = false;
        this.router.navigate(["../edit", apiResponse.data?.id], {
          relativeTo: this.route,
        });
      },
      error: (error) => {
        //console.log("error on creating turma:", error);
        this.isLoading = false;
      },
      complete: () => {
        //console.log("complete create Turma");
      },
    });
  }
}
