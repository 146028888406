import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { Subject } from "../models/subject.model";
import { BehaviorSubject, Observable, catchError, map, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";

@Injectable({
  providedIn: "root",
})
// TODO - Rename Discipline to subject, ou não?
export class DisciplineService extends BaseService<Subject> {
  override baseUrl: string = `${this.baseUrl}/subject`;
  private subjectSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public getSubjects(): Observable<any> {
    return this.subjectSubject.asObservable();
  }

  public getAllSubjects(
    page: number = 1,
    limit: number = 50
  ): Observable<ApiResponse<PaginationData<Subject>>> {
    const url = `${this.baseUrl}?withUserSubjectClass=true&limit=${limit}&page=${page}`;
    return this.api.get<ApiResponse<PaginationData<Subject>>>(url).pipe(
      tap((_) => this.log(`fetched Entity`)),
      catchError(this.handleError(`fetch `))
    );
  }

  public getSubjectsByGradeId(
    gradeId: string,
    withDefault: boolean = false,
    page: number = 1,
    limit: number = 50
  ): Observable<ApiResponse<PaginationData<Subject>>> {
    const url = `${this.baseUrl}?grade_id=${gradeId}&withUserSubjectClass=true&withDefault=${withDefault}&limit=${limit}&page=${page}`;
    return this.api.get<ApiResponse<PaginationData<Subject>>>(url).pipe(
      tap((_) => this.log(`fetched Entity by gradeId ${gradeId}`)),
      catchError(this.handleError(`fetch by gradeID ${gradeId}`))
    );
  }
  public getSubjectsByClassId(
    classId: string,
    withDefault: boolean = false,
    page: number = 1,
    limit: number = 50
  ): Observable<ApiResponse<PaginationData<Subject>>> {
    const url = `${this.baseUrl}?class_id=${classId}&withDefault=${withDefault}&withUserSubjectClass=true&limit=${limit}&page=${page}`;
    return this.api.get<ApiResponse<PaginationData<Subject>>>(url).pipe(
      tap((_) => this.log(`fetched Entity by gradeId ${classId}`)),
      catchError(this.handleError(`fetch by gradeID ${classId}`))
    );
  }

  public getListOfSubjectAndTeacher(
    classId: string,
    page: number = 1,
    limit: number = 50
  ): Observable<ApiResponse<PaginationData<Subject>>> {
    const url = `${this.baseUrl}?class_id=${classId}&withUserSubjectClass=true&limit=${limit}&page=${page}`;
    return this.api.get<ApiResponse<PaginationData<Subject>>>(url).pipe(
      tap((_) => this.log(`fetched Entity by gradeId ${classId}`)),
      catchError(this.handleError(`fetch by gradeID ${classId}`))
    );
  }
  public getRawDisciplinaContent(
    apiResponse: ApiResponse<PaginationData<Subject>>
  ): Subject[] {
    return apiResponse.data?.data!;
  }
}
