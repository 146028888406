import { Component, Input, Renderer2 } from "@angular/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as JSZip from 'jszip';
import { ExamScoresService } from "../services/exam-scores.service";
import { BoletimPdfService } from "../services/boletim-pdf.service";
import { MatSnackBar } from "@angular/material/snack-bar";


@Component({
  selector: "app-report-card",
  templateUrl: "./report-card.component.html",
  styleUrls: ["./report-card.component.scss"],
})



export class ReportCardComponent {

  constructor(
    private examScoresService: ExamScoresService,
    private boletimPdfService: BoletimPdfService,
    private snackBar: MatSnackBar
  ) { }

  @Input() idsExported!: any[];

  showProgressBar = false;
  progress = 0;

  getFormattedProgress(value: number): number {
    return Math.floor(value); // Arredonda para baixo, removendo qualquer parte decimal
  }

  api_response = {
    "statusCode": 200,
    "message": "Operação bem-sucedida",
    "data": [
      {
        "school": "Colégio x",
        "matricula": "00002",
        "student": "Teste Nao populado",
        "birthdate": "2009-01-01",
        "grade": "1 ano",
        "class": "XPTO",
        "classShift": "Manhã",
        "schoolYear": "2024",
        "city": "BELÉM",
        "state": "PARÁ",
        "status": "CURSANDO",
        "headers_table": [
          "Código Disciplina",
          "Componente Curricular",
          "1ª Av",
          "2ª Av",
          "3ª Av",
          "4ª Av",
          "Nota de corte",
          "Média aluno",
          "Carga Horária",
          "Freq %",
          "Faltas",
          "Resultado (Situação)"
        ],
        "exams_table": [
          [
            "#20",
            "Redação",
            "-",
            "-",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        ]
      },
      {
        "school": "Colégio y",
        "matricula": "00001",
        "student": "Teste Nao populado",
        "birthdate": "2009-01-01",
        "grade": "1 ano",
        "class": "XPTO",
        "classShift": "Manhã",
        "schoolYear": "2024",
        "city": "BELÉM",
        "state": "PARÁ",
        "status": "CURSANDO",
        "headers_table": [
          "Código Disciplina",
          "Componente Curricular",
          "1ª Av",
          "2ª Av",
          "3ª Av",
          "4ª Av",
          "Nota de corte",
          "Média aluno",
          "Carga Horária",
          "Freq %",
          "Faltas",
          "Resultado (Situação)"
        ],
        "exams_table": [
          [
            "#20",
            "Redação",
            "-",
            "-",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
          [
            "#1",
            "Portugues",
            "2.0",
            "9.0",
            "6.0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        ]
      },
      {
        "school": "Escola Estadual de Ensino Médio Professora Maria José Leite",
        "matricula": "00003",
        "student": "antonio dev",
        "birthdate": "2024-01-05T00:00:00.000Z",
        "grade": "1 ano",
        "class": "Turma xaad",
        "classShift": "Manhã",
        "schoolYear": "2024",
        "city": "BELÉM",
        "state": "PARÁ",
        "status": "CURSANDO",
        "headers_table": [
          "COD",
          "Disciplina",
          "Média Anual",
          "Freq %",
          "Faltas",
          "Resultado"
        ],
        "exams_table": []
      }
    ]
  }

  ngOnInit(): void {
    this.boletimPdfService.currentData.subscribe(data => {
      this.idsExported = data.data
      if (this.idsExported.length > 0) {
        if (this.idsExported.length == 1) {

          this.examScoresService.getExamsScoresService(data.turma, this.idsExported)
            .subscribe({
              next: (data) => {
                this.generate1PDF(data.data[0]);
              },
              error: (error) => {
                //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
              },
              complete: () => {
                // console.log("fetch infos estudante complete");
              },
            });

        } else {

          this.examScoresService.getExamsScoresService(data.turma, this.idsExported)
            .subscribe({
              next: (data) => {
                this.generateMultiplePDFs(data.data)
              },
              error: (error) => {
                //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
              },
              complete: () => {
                // console.log("fetch infos estudante complete");
              },
            });

        }
      }
    });
    // if (this.api_response.data.length > 1) {
    //   this.generateMultiplePDFs(this.api_response.data);
    // } else {
    //   this.generate1PDF();
    // }
  }

  async generateMultiplePDFs(data: any) {
    this.showProgressBar = true;
    this.progress = 0;
    const zip = new JSZip();

    const promises = data.map(async (element: any, index: number) => {
      if (element.exams_table.length > 0) {
        const pdf = await this.generateMorePDF(element, index);
        const turma = 'turma-' + element.class;
        const aluno = 'aluno-' + element.student_number;
        zip.file(`${turma}-${aluno}.pdf`, pdf);
        this.progress = ((index + 1) / data.length) * 100;
        if (index < data.length - 1) {
          await this.sleep(2000);
        }
      }
    });

    await Promise.all(promises);

    this.showProgressBar = false;
    const content = await zip.generateAsync({ type: 'blob' });
    this.downloadZIP(content);
  }


  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  generateHTML(element: any, index: any) {
    let linhas_table = "<tr>"
    let head_table = `<thead>
      <tr>`
    element.headers_table.forEach((element: any) => {
      head_table += `<th>` + element + `</th>`
    });
    head_table += `</tr>
    </thead>`

    element.exams_table.forEach((element2: any) => {
      element2.forEach((element3: any) => {
        if (element3.includes("#")) {
          element3 = '#'+element3.substring(element3.length - 6, element3.length);
        }
        linhas_table += `
            <td>${element3}</td>
        `;

      });
      linhas_table += `</tr>`
    });


    let content = `
    <style>
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
    
    th, td {
      border: 1px solid #ccc;
      border-right: 8px solid #fff;
      border-left: 0px;
      border-top: 0px;
      padding: 4px;
      text-align: center;
    }
    
    th {
      background-color: #f4f4f4;
    }

    td {
      background-color: #fff;
  }
    
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
    
    tr:hover {
      background-color: #f1f1f1;
    }
    
    td {
      text-align: center;
    }
    
    th:first-child, td:first-child {
      text-align: left;
    }
    #hidden-content-${index}{
      padding:  50px;
    }
    .row {
      display: flex;
      width: 100%;
    }

    .item {
      border: solid 1px #BBBBBB;
      border-top: 0px;
      border-radius: 0px;
      margin: 3px;
      padding: 5px;
    }
    </style>
    <div id="hidden-content-${index}" style="width: 800px;">
    <img style="width:150px; height:100%; padding-left:10px; padding-bottom:80px; padding-top:45px;" src="../../../../../assets/images/logo/logo azul 1.png">
    <h1>Boletim Escolar</h1>
    <h2>Dados do estudante</h2>

    <div>
      <div class="row">
        <p class="item" style="width: 100%;"><strong>Escola:</strong> ${element.school}</p>
      </div>
      <div class="row">
        <p class="item" style="width: 75%;"><strong>Aluno(a):</strong> ${element.student}</p>
        <p class="item" style="width: 25%;"><strong>Nascimento:</strong> ${element.birthdate}</p>
      </div>
      <div class="row">
        <p class="item" style="width: 50%;"><strong>Série:</strong> ${element.grade}</p>
        <p class="item" style="width: 50%;"><strong>Turma:</strong> ${element.class}</p>
      </div>
      <div class="row">
        <p class="item" style="width: 50%;"><strong>Turno:</strong> ${element.classShift}</p>
        <p class="item" style="width: 50%;"><strong>Ano:</strong> ${element.schoolYear}</p>
      </div>
      <div class="row">
        <p class="item" style="width: 33%;"><strong>Cidade:</strong> ${element.city}</p>
        <p class="item" style="width: 33%;"><strong>Estado:</strong> ${element.state}</p>
        <p class="item" style="width: 33%;"><strong>Situação:</strong> ${element.status}</p>
      </div>
    </div>
    <h2 style="margin-top: 40px; color: #3A40C7; font-size: 22px; font-weight: 400;">Boletim</h2>
    <table>
    ${head_table}
    <tbody>
    ${linhas_table}
    </tbody>
    </table>
    <div style="margin-top: 160px">
    <div style="display: flex; justify-content: space-between">
      <div style="text-align: center">
        <div style="border-bottom:1px solid black; width:305px"></div>
        <p>Diretor (a)</p>
      </div>
      <div style="text-align: center">
        <div style="border-bottom:1px solid black; width:305px"></div>
        <p>Coordenador(a) pedagógico</p>
      </div>
    </div>
  </div>
    </div>
  `;
    return content;
  }

  generateMorePDF(element: any, index: number): Promise<Blob> {
    this.snackBar.open(
      "Exportação inciada",
      "Fechar",
      {
        duration: 1000,
        panelClass: ["mat-snackbar-success"],
      }
    );
    return new Promise((resolve, reject) => {
      const content = this.generateHTML(element, index);

      // Criar um container invisível
      const hiddenDiv = document.createElement('div');
      hiddenDiv.style.position = 'absolute';
      hiddenDiv.style.left = '-9999px';
      hiddenDiv.innerHTML = content;
      document.body.appendChild(hiddenDiv);

      const data = document.getElementById(`hidden-content-${index}`);

      if (data) {
        html2canvas(data).then(canvas => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          const contentDataURL = canvas.toDataURL('image/png');
          let pdf = new jsPDF('p', 'mm', 'a4');
          const position = 0;
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

          // if (quantidade de alunos for igual a 1)
          // pdf.save(`file-${index}.pdf`);
          // resolve();

          const pdfBlob = pdf.output('blob');
          document.body.removeChild(hiddenDiv);
          data.remove();
          resolve(pdfBlob);
          this.snackBar.open(
            "Exportação finalizada",
            "Fechar",
            {
              panelClass: ["mat-snackbar-success"],
            }
          );
        }).catch(error => {
          //console.error('Erro ao gerar o PDF:', error);
          reject();
        });
      } else {
        //console.error('Erro ao gerar o PDF: elemento HTML não encontrado');
        reject();
      }
    });
  }

  generate1PDF(element: any) {
    this.snackBar.open(
      "Exportação inciada",
      "Fechar",
      {
        duration: 1000,
        panelClass: ["mat-snackbar-success"],
      }
    );
    this.showProgressBar = true;
    const content = this.generateHTML(element, 1);

    // Criar um container invisível
    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.left = '-9999px';
    hiddenDiv.innerHTML = content;
    document.body.appendChild(hiddenDiv);


    const data = document.getElementById(`hidden-content-1`);

    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        let pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

        // if (quantidade de alunos for igual a 1)
        const turma = 'turma-' + element.class;
        const aluno = 'aluno-' + element.student_number;
        pdf.save(`${turma}-${aluno}.pdf`);
        document.body.removeChild(hiddenDiv);
        data.remove();
        this.showProgressBar = false;
        this.snackBar.open(
          "Exportação finalizada",
          "Fechar",
          {
            panelClass: ["mat-snackbar-success"],
          }
        );
      }).catch(error => {
        //console.error('Erro ao gerar o PDF:', error);
      });
    }
  }

  downloadZIP(content: Blob) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = 'pdfs.zip';
    link.click();
  }
}