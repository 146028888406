import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../services/auth.service";
import { firstValueFrom, map, Observable, take } from "rxjs";

// Enum com os valores possíveis para o campo role_id
export enum ERole {
  TEACHER = 2,
  TEACHER_ADMINISTRATOR = 3,
  SCHOOL_ADMINISTRATOR = 4,
  ADMINISTRATOR = 5,
}
@Injectable()
export class AuthGuard {
  constructor(
    private authService: AuthService, private router: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const user = await firstValueFrom(this.authService.currentUser.pipe(take(1)));
    if (!user || !user.id) {
       await firstValueFrom(this.authService.populate());
    }
    const requiredRoles: string[] = route.data["roles"];
    // Verifica o estado atual do usuário
    const revalidate_user = await firstValueFrom(this.authService.currentUser.pipe(take(1)));
    if (!revalidate_user || !revalidate_user.id) {
      this.router.navigate(['/login']);
      return false;
    }
    const userRole = Object.keys(ERole).find(key => ERole[key as keyof typeof ERole] === Number(revalidate_user.role_id));
    const hasRequiredRole = requiredRoles.some((role: string) => role === userRole);

    if (!hasRequiredRole) {
      this.router.navigate(['/access-denied']);
      return false;
    }
    return true; // Usuário autenticado e com a role apropriada
  }
}
