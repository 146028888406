import { Injectable } from '@angular/core';
import { Subject } from "../models/subject.model";
import { BaseService } from 'src/app/core/services/base.service';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UserActivityService extends BaseService<Subject> {

  override baseUrl: string = `${this.baseUrl}/user-activity`;

  getUsersActivity(id: string, page: number, limit: number, status: string): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&activity_id=${id}&status=${status}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  getAllUsersActivity(id: string, page: number, limit: number): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&activity_id=${id}`;
    return this.api.get<any>(url).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  devolverActivity(id_activity: string, data: any) {
    const url = `${this.baseUrl}/${id_activity}/reject`;
    return this.api.patch<any>(url, data).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

  corrigirActivity(id_activity: string, data: any) {
    const url = `${this.baseUrl}/${id_activity}`;
    return this.api.patch<any>(url, data).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }

}
