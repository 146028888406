import { Component } from "@angular/core";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { AuthService } from "src/app/core/services/auth.service";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { SubjectContentService } from "../../../shared/services/subject-content.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SubjectContent } from "../../../shared/models/subject-content.model";
import { PublishDialogComponent } from "../../shared/publish-dialog/publish-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { GradeService } from "../../../shared/services/grade.service";
import { Grade } from "../../../shared/models/grade.model";
import { DeleteDialogComponent } from "../../../shared/components/delete-dialog/delete-dialog.component";

@Component({
  selector: "app-manage-conteudo",
  templateUrl: "./manage-conteudo.component.html",
  styleUrls: ["./manage-conteudo.component.sass"],
})
export class ManageConteudoComponent {
  allDisciplines: any;
  disciplinesFromUser: any;
  contentFromDiscipline: any;
  userId!: string;
  selectedOption: any;
  contentInDiscipline$!: Observable<ApiResponse<any> | null>;
  contentWoutDiscipline$!: Observable<ApiResponse<any> | null>;
  gradeList!: Grade[];
  isLoading: boolean = false;
  isLoadingWout: boolean = false;
  tooltiptext = {
    pdf: "",
    video: "Esse conteúdo possui um embed de vídeo para assistir",
    published: "",
    notPublished: "",
  };

  constructor(
    private disciplineService: DisciplineService,
    private subjectContentService: SubjectContentService,
    private gradeService: GradeService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoadingWout = true;
    this.userId = this.authService.getCurrentUser().id!;
    this.disciplineService.getAllSubjects().subscribe({
      next: (apiData) => {
        this.allDisciplines = apiData.data?.data;
        this.disciplinesByUser();
      },
      error: (error) => {
        // console.log(
        //   "error in fetch getAllSubjects function from discipline ",
        //   error
        // );
      },
      complete: () => {
        const distinctGradeIds: any[] = [
          ...new Set(this.disciplinesFromUser.map((obj: any) => obj.gradeId)),
        ];
        this.gradeService.getAll().subscribe({
          next: (apiData) => {
            this.gradeList = apiData.data!.data;
          },
          complete: () => {
            this.gradeList = this.gradeList.filter((item) =>
              distinctGradeIds.includes(item.id)
            );
            this.disciplinesFromUser = this.disciplinesFromUser.map(
              (subject: any) => {
                const gradeContent = this.gradeList.find(
                  (grade) => grade.id === subject.gradeId
                );
                return { ...subject, gradeTitle: gradeContent?.title };
              }
            );
          },
        });
      },
    });
    this.loadContentWoutSubject();
  }
  disciplinesByUser() {
    this.disciplinesFromUser = this.allDisciplines.map((subject: any) => {
      const classesOfUser = subject.user_subject_class
        .filter((data: any) => data?.user?.id == this.userId)
        .map((data: { class: { title: any } }) => data.class.title);
      return {
        subjectId: subject.id,
        subjectTitle: subject.title,
        gradeId: subject.grade_id,
        classesForUser: classesOfUser,
      };
    });
    this.disciplinesFromUser = this.disciplinesFromUser.filter(
      (item: any) => item.classesForUser.length > 0
    );
  }

  onOptionSelected(): void {
    this.isLoading = true;
    this.loadContentAsync(this.selectedOption);
  }

  loadContentWoutSubject() {
    this.contentWoutDiscipline$ =
      this.subjectContentService.getSubjectContentOrganizedWoutSubjectId();
    this.contentWoutDiscipline$.subscribe({
      next: (apiData) => {
        //console.log(apiData);
        // this.contentFromDiscipline = apiData!.data;
        // console.log(this.contentFromDiscipline);
      },
      complete: () => {
        this.isLoadingWout = false;
      },
    });
  }

  loadContentAsync(selectedOption: any): void {
    this.contentInDiscipline$ =
      this.subjectContentService.getSubjectContentOrganizedBySubjectId(
        selectedOption
      );
    this.contentInDiscipline$.subscribe({
      next: (apiData) => {
        this.contentFromDiscipline = apiData!.data;
        //console.log(this.contentFromDiscipline);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  loadData(): void {
    this.contentInDiscipline$ =
      this.subjectContentService.getSubjectContentOrganizedBySubjectId(
        this.selectedOption
      );
  }

  showActions(subject_content: any, type: string) {
    return subject_content.attachments.some((item: any) => item?.type === type);
  }

  openConteudoDialog(contentId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "450px";
    dialogConfig.data = [this.authService.getCurrentUser(), contentId];
    this.dialog
      .open(PublishDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (this.selectedOption) this.loadContentAsync(this.selectedOption);
          this.loadContentWoutSubject();
        }
      });
  }

  // openDeleteDialog(contentId:number){

  // }

  copyContent(subjectContent: SubjectContent) {
    const copySubjectContent: SubjectContent = {
      title: `${subjectContent.title} cópia`,
      description: subjectContent.description,
      topic: subjectContent.topic,
      content: subjectContent.content,
      attachments: subjectContent.attachments,
      image: subjectContent.image,
      type: subjectContent.type,
      read_time_avg: subjectContent.read_time_avg,
      subject_id: subjectContent.subject_id,
      activity_id: subjectContent.activity_id,
    };
    let copySubjectContentId!: string;
    this.subjectContentService.save(copySubjectContent).subscribe({
      next: (apiData) => {
        copySubjectContentId = apiData.data?.id!;
      },
      error: (error) => {
        // console.error(
        //   `error in copying a subjectContent with id:${subjectContent.id}`
        // );
      },
      complete: () => {
        this.router.navigate(["edit", subjectContent.id], {
          relativeTo: this.route,
        });
      },
    });
  }

  deleteContent(contentId: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: { message: "Você tem certeza que deseja deletar esse conteúdo?" },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.subjectContentService.delete(contentId).subscribe({
          next: (apiData) => {
            //console.log(`Conteudo deletado com sucesso com id ${contentId}`);
          },
          complete: () => {
            this.loadData();
            this.loadContentWoutSubject();
          },
        });
      }
    });
  }
}
