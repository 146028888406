import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuth } from 'src/app/core/models/user-login.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MatDialog } from '@angular/material/dialog';
import { SelectSchoolComponent } from '../select-school/select-school.component';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.sass"],
})
export class LoginComponent {
  hide = true;
  error = null;
  isLoading = false;

  // cpfControl = new FormControl("", [Validators.required]);
  // emailControl = new FormControl("", [Validators.required, Validators.email]);
  // passwordControl = new FormControl("", [Validators.required]);
  // rememberMeControl = new FormControl(false);
  currentUser: UserAuth = {
    name: "",
    password: "",
    platform: "web",
  };
  constructor(
    public dialog: MatDialog, private router: Router, private authService: AuthService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
  }
  toggleHide() {
    this.hide = !this.hide;
}
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  redirectTo() {
    const dialogRef = this.dialog.open(SelectSchoolComponent, {
      width: "350px",
      data: { message: "Selecione a escola que deseja? " },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      const currentUser = this.authService.getCurrentUser();
      if (currentUser != null && currentUser.id != null) {
        this.error = null;
        this.isLoading = false;
        this.router.navigate(["/privado/main"]);
      } else {
        this.error = null;
        this.isLoading = false;
        this.router.navigate(["/login"]);
      }
    });
  }

  errorHandling(error: any) {
    this.isLoading = false;
    this.error = error.error.error? error.error.error : "Erro desconhecido";
    this.openSnackBar(this.error??"Erro desconhecido", "Fechar");
  }
  
  onSubmit() {
    this.isLoading= true;
    this.authService.attemptAuth(this.currentUser).subscribe(
      {
        next: (response: any) => {
          if(response.data.user.role_id == 1){
            this.errorHandling(null);
            return;
          }
          this.redirectTo();
        },
        error: (error) => {
          this.errorHandling(error);
        },
      }
    );
  }
}
