import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { School } from "../../private/shared/models/school.model";
import { SchoolService } from "../../private/shared/services/school.service";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-select-school",
  templateUrl: "./select-school.component.html",
  styleUrls: ["./select-school.component.sass"],
})
export class SelectSchoolComponent {
  schools: School[] = [];
  selectedSchoolId: string = "0";
  private schoolsSubscription: Subscription;
  private selectedSchoolIdSubscription: Subscription;
  countData: number = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private schoolService: SchoolService,
    public dialogRef: MatDialogRef<SelectSchoolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {
    this.schoolsSubscription = this.schoolService
      .getSchools()
      .subscribe((schools) => {
        this.schools = schools;
        this.countData++;
        if (this.countData > 1 && this.schools.length == 0) {
          //TODO: Melhorar esse alerta
          alert(
            "Não há escolas cadastradas para seu usuário, entre em contato com a administração"
          );
        }
      });

    this.selectedSchoolIdSubscription = this.schoolService
      .getSelectedSchoolId()
      .subscribe((id) => {
        this.selectedSchoolId = id;
      });

    this.dialogRef.afterClosed().subscribe((result) => {
      const selectedSchool = this.schools.find(
        (school) => school.id == this.selectedSchoolId
      );
      if (selectedSchool) {
        this.handleSelection(selectedSchool);
      }
    });
  }

  ngOnInit() {
    this.fetchDefaultSchool();
  }

  private async fetchDefaultSchool() {
    try {
      await this.schoolService.getSchoolList();
    } catch (error) {
      //console.error("Error occurred:", error);
    }
  }
  logout() {
    this.authService.purgeAuth();
    this.dialogRef.close(false);
  }
  isLoggedIn() {
    return this.authService.getCurrentUser().id;
  }

  onSelect(event: MatSelectChange) {
    const selectedId = event.value as string;
    const selectedSchool = this.schools.find(
      (school) => school.id == selectedId
    );
    if (selectedSchool) {
      this.handleSelection(selectedSchool);
    }
  }

  handleSelection(school: School) {
    const schoolObject = {
      id: school.id,
      title: school.title,
    };
    this.schoolService.changeSchool(schoolObject, false);
  }

  ngOnDestroy() {
    if (this.schoolsSubscription) {
      this.schoolsSubscription.unsubscribe();
    }
    if (this.selectedSchoolIdSubscription) {
      this.selectedSchoolIdSubscription.unsubscribe();
    }
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
