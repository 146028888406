import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ExamScoresService } from "../../../../shared/services/exam-scores.service";
import { MessagesService } from "src/app/modulos/private/shared/services/messages.service";
import { MessageModel } from "src/app/modulos/private/shared/models/messages/messages.model";

export interface DialogDataOld {
  currentCount: number;
  totalCount: number;
  imageSrc: string;
  class: string;
  name: string;
  score: number;
  comments: string;
}
export interface DialogData {
  examRegistryId: string;
  classId: string;
  className: string;
}

export interface popUpData {
  score_exam_id: string;
  user_name: string;
  user_avatar: string;
  user_id: string;
  chat_id: string;
  score: number | null;
  comments: string;
  message_id: string;
  modified_score?: boolean;
  modified_comments?: boolean;
}

@Component({
  selector: "app-dialog-score-plus-comment",
  templateUrl: "./dialog-score-plus-comment.component.html",
  styleUrls: ["./dialog-score-plus-comment.component.sass"],
})
export class DialogScorePlusCommentComponent {
  examRegistryId!: string;
  selected!: number;
  inputClass!: string;
  dataRegistry!: popUpData[];
  loading!: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogScorePlusCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private examScoresService: ExamScoresService,
    private messagesService: MessagesService,
  ) {
    this.examRegistryId = data.examRegistryId;
    this.inputClass = data.className;
    this.loadData();
  }

  // TODO - Melhorar o score e o comentário no banco de dados;
  loadData(): void {
    this.loading = true;
    this.examScoresService.getAllBatch(this.examRegistryId).subscribe({
      next: (response) => {
        this.dataRegistry = response.data.data.map((element: any) => {
          return {
            score_exam_id: element.id,
            user_name: element.student.name,
            user_avatar: element.student.avatar,
            user_id: element.student.id,
            chat_id: element.chat_id,
            score: element.score,
            comments: element.messages[0]?.text,
            message_id: element.messages[0]?.id,
            modified_score: false,
            modified_comments: false,
          };
        });
        const inputindex = this.dataRegistry.findIndex(
          (element) => element.score == null
        );

        this.selected = inputindex != -1 ? inputindex : 0;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.dialogRef.close();
      },
      complete: () => {},
    });
  }

  onClose(): void {
    this.updateData(this.selected);
    this.dialogRef.close();
  }

  isFirst(): boolean {
    return this.selected === 0;
  }

  isLast(): boolean {
    return this.selected === this.dataRegistry.length - 1;
  }

  previous(): void {
    if (!this.isFirst()) {
      this.updateData(this.selected);
      this.selected--;
    }
  }

  next(): void {
    if (!this.isLast()) {
      this.updateData(this.selected);
      this.selected++;
    }
  }

  finish(): void {
    if (this.isLast()) {
      this.updateData(this.selected);
      this.dialogRef.close();
    }
  }

  updateData(index: number) {
    this.loading = true;
    if (this.dataRegistry[index].modified_score) {
      this.saveScore(index, this.dataRegistry[index]);
    }

    if (this.dataRegistry[index].modified_comments) {
      this.saveComments(index, this.dataRegistry[index]);
    }

    if (
      !this.dataRegistry[index].modified_comments &&
      !this.dataRegistry[index].modified_score
    ) {
      this.loading = false;
    }
  }

  saveScore(index: number, dataRegistryToSave: popUpData): void {
    const scoreExamId = dataRegistryToSave.score_exam_id;
    const dataToUpdate = {
      score: dataRegistryToSave.score
    };
    this.examScoresService.update(scoreExamId,dataToUpdate).subscribe({
      next:() => {
        // console.log("Nota atualizada via dialog com sucesso")
      }, error: (error) => {
        // console.log("error na tentativa de update de nota", error)
      }, complete: () => {
      }
    })
    this.dataRegistry[index].modified_score = false;
    this.loading = false;
  }
  saveComments(index: number, dataRegistryToSave: popUpData): void {
    const dataToUpdate: MessageModel = {
      text: dataRegistryToSave.comments,
      attachments: [],
      chat_id: dataRegistryToSave.chat_id,
      exam_score_id: dataRegistryToSave.score_exam_id+""
    };
    if(dataRegistryToSave.message_id != null && dataRegistryToSave.message_id != ''){ 
      this.messagesService.delete(dataRegistryToSave.message_id).subscribe({
        next:(response) => {
          // console.log("Comentário anterior deletado com sucesso")
        }, error: (error) => {
          // console.log("error na tentativa de deletar", error)
        }, complete: () => {
        }
      })
    }
    this.messagesService.save(dataToUpdate).subscribe({
      next:(response) => {
        // console.log("Comentário criado com sucesso")
      }, error: (error) => {
        // console.log("error na tentativa de deletar", error)
      }, complete: () => {
      }
    })
    this.dataRegistry[index].modified_comments = false;
    this.loading = false;
  }

  changeScore(): void {
    this.dataRegistry[this.selected].modified_score = true;
  }

  changeComment(): void {
    this.dataRegistry[this.selected].modified_comments = true;
  }
}
