import { Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'ativ-edu-web';

  constructor(
    private router: Router, private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}
  ngOnInit(): void {
    this.isLoggedIn();
  }

  
  async isLoggedIn(): Promise<boolean> {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser != null && currentUser.id != null) {
      return true;
    }else{
      try {
          const token = this.localStorageService.getToken();
          if (!token) {
            return false;
          }
          await firstValueFrom(this.authService.populate());
          // Verifica se o usuário está logado após o populate
          const currentUser = this.authService.getCurrentUser();
          // Redireciona usuário caso esteja logado
          if(currentUser != null && currentUser.id != null && this.router.url === '/login'){
            this.router.navigate(["/privado/main"]);
          }
          return true;
      } catch (error) {
        return false;
      }
    }
  }

}

