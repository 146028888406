import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { ActivityService } from "../../../shared/services/activity.service";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { environment } from "../../../../../../environments/env";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { UserSubjectClassService } from "../../../shared/services/user-subject-class.service";
import { AuthService } from "src/app/core/services/auth.service";
import { ApiService } from "src/app/core/services/api.service";
import { Attachment } from "../../../shared/models/attachment.model";

const API_URL = environment.api_url;

@Component({
  selector: "app-edit-atividade",
  templateUrl: "./edit-atividade.component.html",
  styleUrls: ["./edit-atividade.component.sass"],
})
export class EditAtividadeComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private activityService: ActivityService,
    private localStorageService: LocalStorageService,
    private userSubjectClassService: UserSubjectClassService,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    this.currentUser = this.authService.getCurrentUser();
    this.getUserSubjectClassService();
    this.route.params.subscribe(params => {
      this.activityId = params['id'];
    });
    this.route.queryParamMap.subscribe((params) => {
      this.copyParam = params.get("copy");
    });
  }

  public uploader: FileUploader = new FileUploader({
    url: API_URL + "/files/upload",
    itemAlias: "file",
    headers: [{ name: "Accept", value: "application/json" }],
  });
  copyParam: any;

  youtubeUrlValidatorPattern = /youtu(?:\.be|be\.com)/i;
  actiivityForm: any;
  submited: boolean = false;
  dragActive: boolean = false;

  selectFile: string = "";

  currentUser;

  array_files: Attachment[] = [];

  sub: any;
  subj: any;

  classId: string = '0';
  class_subject: any[] = []

  activityId: string = '0';
  activity: any;
  isLoading = false;

  max_score_select: boolean = false
  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Atividades");

    this.actiivityForm = this.formBuilder.group({
      discipline: ["", [Validators.required]],
      title: ["", [Validators.required]],
      comand: ["", [Validators.required]],
      max_score: [null],
      has_score: [true],
      start_date: ["", [Validators.required]],
      end_date: ["", [Validators.required]],
      url2: [null, [Validators.pattern(this.youtubeUrlValidatorPattern)]],
      url: [null],
      published: [false],
      class_selected: ['', [Validators.required]],
    });
    this.getAcitivity(this.activityId)
    this.uploadFiles();
  }

  submitUser() {
    try {
      this.isLoading = true;
      let activity;
      if (this.actiivityForm.valid) {

        if (this.f.has_score.value === true && (this.f.max_score.value === null || this.f.max_score.value === '')) {
          this.isLoading = false;
          this.submited = true;
          this.snackBar.open(
            "Operação com problema, preencha os campos obrigatórios",
            "Fechar",
            {
              duration: 3000,
              panelClass: ["mat-snackbar-success"],
            }
          );
          return;
        }

        let current_date: Date = new Date();
        let date_String: string = current_date.toString();
        if (this.f.url2.value != "" && this.f.url2.value != null) {
          if (
            this.array_files.find((file) => file.url == this.f.url2.value) ==
            undefined
          ) {
            this.array_files.push({
              title: "Vídeo",
              type: "youtube",
              url: this.f.url2.value,
              created_at: date_String,
            });
          }
        }

        let published = this.f.published.value ? new Date() : null;

        let maxScore = this.f.max_score.value === '' ? 0 : this.f.max_score.value;

        activity = {
          "title": this.f.title.value,
          "description": this.f.comand.value,
          "max_score": maxScore,
          "has_score": this.f.has_score.value,
          "status": 'Aberta',
          "type": "Atividade",
          "user_id": this.currentUser.id,
          "subject_id": this.f.discipline.value,
          "attachments": this.array_files,
          "class_id": this.f.class_selected.value,
          "start_date": this.f.start_date.value.sub.id,
          "end_date": this.f.end_date.value,
          "published": published
        }

        if (this.copyParam == "false") {
          this.activityService.editActivity(this.activityId, activity).subscribe({
            next: (data) => {
              this.snackBar.open("Enviado com sucesso", "Fechar", {
                duration: 3000,
                panelClass: ["mat-snackbar-success"],
              });
              this.router.navigate(["/privado/atividade"], { queryParams: { published: this.f.published.value } });
            },
            error: (error) => {
              // this.erro();
              // console.error(
              //   "Erro no fetch e/ou processamento da informações do estudante: ",
              //   error
              // );
            },
            complete: () => {
              this.isLoading = false;
              // console.log("fetch infos estudante complete");
            },
          });
        } else {
          this.activityService.postActivity(activity).subscribe({
            next: (data) => {
              this.snackBar.open("Enviado com sucesso", "Fechar", {
                duration: 3000,
                panelClass: ["mat-snackbar-success"],
              });
              this.router.navigate(["/privado/atividade"], { queryParams: { published: this.f.published.value } });
            },
            error: (error) => {
              // this.erro();
              this.isLoading = false;
              this.submited = true;
              this.snackBar.open(
                "Operação com problema, tente novamente mais tarde",
                "Fechar",
                {
                  duration: 3000,
                  panelClass: ["mat-snackbar-success"],
                }
              );
            },
            complete: () => {
              // console.log("fetch infos estudante complete");
            },
          });
        }
      } else {
        this.isLoading = false;
        this.submited = true;
        this.snackBar.open(
          "Operação com problema, preencha os campos obrigatórios",
          "Fechar",
          {
            duration: 3000,
            panelClass: ["mat-snackbar-success"],
          }
        );
      }
    }
    catch (e) {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, tente novamente mais tarde",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }

  }

  get f() {
    return this.actiivityForm.controls;
  }

  cancelar() {
    this.router.navigate(["/privado/atividade"]);
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // this.uploader = event.dataTransfer.files;
    this.uploader.uploadAll();

    this.dragActive = false;
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.add('full-active');

    this.dragActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.remove('full-active');

    this.dragActive = false;
  }

  MAX_SIZE = 3 * 1024 * 1024;
  validateFileSize(file: any) {
    if (file.size > this.MAX_SIZE) {
      this.snackBar.open(
        "Erro: Um arquivo é muito grande e por isso não foi enviado. O tamanho máximo permitido é de 3MB.",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
      return false;
    }
    return true;
  }

  validateFile(file: any) {
    for (let i = 0; i < file.uploader.queue.length; i++) {
      if (!this.validateFileSize(file.uploader.queue[i].file)) {
        return false;
      }
    }
    return true;
  }

  onFileChange(event: any) {
    const files = event.target.files;
    this.uploader.uploadAll();
    // this.uploadFiles(files);
  }
  uploadFiles() {
    //file
    // let token_session = this.jwtService.getTokenSession();
    var uo: FileUploaderOptions = {
      url: API_URL + "/files/upload",
    };
    const token_session = this.localStorageService.getToken();
    uo.headers = [
      { name: "Accept", value: "application/json" },
      { name: "Authorization", value: `Bearer ${token_session}` },
    ];
    this.uploader.setOptions(uo);
    var uploaderInvalid = false;
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      if (!this.validateFile(file)) {
        uploaderInvalid = true;
      }
      if (uploaderInvalid) {
        this.uploader.removeFromQueue(file);
        uploaderInvalid = false;
      }
    };
    //this.uploader.onCompleteItem = (item: FileItem,response: string, status: any) => {
    //  alert('File successfully uploaded!');
    //};
    this.uploader.onErrorItem = (item, response, status) =>
      this.onErrorItem(item, response, status);
    this.uploader.onSuccessItem = (item, response, status) =>
      this.onSuccessItem(item, response, status);
  }

  onSuccessItem(item: FileItem, response: string, status: number): any {
    let data = JSON.parse(response); //success server response
    this.array_files.push({
      title: data.data.filename,
      type: data.data.type,
      url: data.data.url,
      created_at: data.data.created_at,
    });
    // alert('Arquivo enviado com sucesso');
    // console.log(this.array_files)
  }
  //user suject class
  //subject_id
  onErrorItem(item: FileItem, response: string, status: number): any {
    let error: any = JSON.parse(response); //error server response
    // alert('Erro ao enviar imagem : ' + error.message);
    alert("Erro ao enviar imagem : " + error.message);
  }

  @ViewChild("fileInput")
  fileInput!: ElementRef;

  onFileSelected(event: any) {
    // Lógica para lidar com o arquivo selecionado
    const files = event.target.files;
    // console.log(files);
    // this.uploader.uploadAll()
    // Adicione aqui a lógica para processar os arquivos, por exemplo, enviá-los para o servidor.
  }

  removeFile(i: any) {
    this.array_files.splice(i, 1);
  }

  getUserSubjectClassService() {
    this.userSubjectClassService
      .getUserSubjectClassService(this.currentUser.id)
      .subscribe({
        next: (data) => {
          this.sub = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title,
            },
            class: {
              id: item.class.id,
              title: item.class.title,
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title,
            },
          }));
          this.subj = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title
            }
          }));
          this.subj = this.subj.filter((item: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
              t.subject.id === item.subject.id && t.grade.id === item.grade.id
            ))
          );
        },
        error: (error) => {
          // console.error(
          //   "Erro no fetch e/ou processamento da informações do estudante: ",
          //   error
          // );
        },
        complete: () => {
          // console.log(this.sub)
          // console.log("fetch infos estudante complete");
        },
      });
  }

  selectClassId(event: any) {
    if (event.isUserInput) {
      this.class_subject = []
      this.sub.forEach((element: any) => {
        if (element.subject.id == event.source.value) {
          this.class_subject.push(element)
        }
      });
      if (event.n == undefined) {
        this.actiivityForm.patchValue({
          class_selected: null
        });
      }
    }
    //   this.classId = class_id;
    // console.log(this.classId)
  }

  getAcitivity(id: string) {
    this.activityService.getOne(id).subscribe({
      next: (data) => {
        this.activity = data.data;
        this.breadcrumbService.set("@edit", `Editando ${this.activity.title!}`);
        // this.selectClassId(this.activity.class_id)
        this.bindValues(data.data);
        // console.log("Class_id 1 " + this.activity.class_id)
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        // console.log("fetch infos estudante complete");
      },
    });
  }

  bindValues(activity: any) {
    // this.classId = activity.class_id;
    this.maxScoreSelect(activity.has_score)
    let maxScore = activity.max_score === 0 ? '' : activity.max_score;
    this.actiivityForm.patchValue({
      discipline: activity.subject_id,
      title: activity.title,
      comand: activity.description,
      max_score: maxScore,
      has_score: activity.has_score,
      start_date: activity.start_date,
      end_date: activity.end_date,
      class_selected: activity.class_id
    });
    if (activity.published != null) {
      this.actiivityForm.patchValue({
        published: true,
      });
    } else {
      this.actiivityForm.patchValue({
        published: false,
      });
    }
    this.selectClassId({ isUserInput: true, n: true, source: { value: activity.subject_id } })
    this.array_files = activity.attachments
    // activity.attachments.forEach((file: any) => {
    //   console.log(file)
    //   if (file.type == 'youtube') {
    //     this.actiivityForm.patchValue({
    //       url2: file.url
    //     });
    //   }
    // })
  }

  file(download: boolean, fileUrl: string | undefined) {
    // console.log(`${API_URL}/${fileUrl}`);
    this.apiService.downloadFile(`${API_URL}/${fileUrl}`).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);

      if (download) {
        const link = document.createElement("a");
        link.href = url;
        link.download = this.extractFileName(fileUrl ?? "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(url);
      }
    });
  }
  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split("/");
    return urlParts[urlParts.length - 1];
  }

  maxScoreSelect(a: any) {
    if (a == false) {
      this.actiivityForm.patchValue({
        max_score: '',
        has_score: a
      });
    } else {
      this.actiivityForm.patchValue({
        has_score: a
      });
    }
  }
}
