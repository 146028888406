import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { User } from "src/app/core/models/user.model";
import { CustomPagination } from "../../models/custom-pagination.model";
import { PageEvent } from "@angular/material/paginator";
import { UserService } from "src/app/modulos/private/shared/services/user.service";
import { LoggingService } from "src/app/core/services/logging.service";
import { DisciplineService } from "src/app/modulos/private/shared/services/discipline.service";
import { StudentService } from "src/app/modulos/private/shared/services/student.service";

const alunoColumnHeader = {
  name: "Aluno",
  username: "Matrícula",
  turma: "Turmas",
};

const disciplinaColumnHeader = {
  name: "Disciplina ",
};

const professorColumnHeader = {
  name: "Professor cadastrado",
};

@Component({
  selector: "app-dialog-table",
  templateUrl: "./dialog-table.component.html",
  styleUrls: ["./dialog-table.component.sass"],
})
export class DialogTableComponent {
  columnHeaders!: { [key: string]: string };
  displayedColumns!: string[]; // column names
  selectionColumns!: string[];
  typeDialogTable!: number;
  dataSource!: MatTableDataSource<any>;
  selection = new SelectionModel<User>(true, []);
  showFilter: boolean = false; // flag to show/hide filter
  isLoading: boolean = true; // flag to show/hide loader
  noContentText!: string;
  noSearchText!: string;
  dataInput: any;

  pagination: CustomPagination = {
    length: 0,
    pageSize: 10,
  };

  update: any

  changeData(event: PageEvent) {
    if (this.typeDialogTable === 1) {
      this.getStudents(event.pageIndex + 1, event.pageSize);
    }
    if (this.typeDialogTable === 2) {
      this.getProfessors(event.pageIndex + 1, event.pageSize);
    }
    if (this.typeDialogTable === 3) {
      this.getSubjects(this.data.gradeId, event.pageIndex + 1, event.pageSize);
    }
  }
  getStudents(pageIndex: number, pageSize: number) {
    this.isLoading = true;
    this.userService.getUserByRole(1, pageIndex, pageSize, true).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.dataInput = data.data!.data!;
        for (let i = 0; i < this.dataInput.length; i++) {
          this.dataInput[i].classname = this.dataInput[i].user_class?.class?.title;
        }
        this.pagination = {
          length: data.data!.totalItems ?? 0,
          pageSize: data.data!.limit ?? 10,
        };
        this.refreshTable();
      },
      error: (error) => {
        this.logService.error(`error in getUserByRole ${error}`);
      },
      complete: () => {
        this.logService.info(`Complete get user by role call`);
      },
    });
  }

  getProfessors(pageIndex: number, pageSize: number) {
    this.isLoading = true;
    this.userService
      .getUserByTwoRoleWithMeta(2, 3, pageIndex, pageSize)
      .subscribe({
        next: (data) => {
          this.isLoading = false;
          this.dataInput = data.data!.data!;
          const userWithEspecializacao = this.dataInput.map((user: any) => {
            const metadataDiscipline = user.metadata.find((item: any) => item.name === 'discipline');
            if (metadataDiscipline) {
              return {
                ...user,
                especiality: metadataDiscipline.value,
              };
            }
            return user;
          })
          this.dataInput = userWithEspecializacao;
          this.pagination = {
            length: data.data!.totalItems ?? 0,
            pageSize: data.data!.limit ?? 10,
          };
          this.refreshTable();
        },
        error: (error) => {
          this.logService.error(`error in getUserByRole ${error}`);
        },
        complete: () => {
          this.logService.info(`Complete get user by role call`);
        },
      });
  }

  getSubjects(gradeId: string, pageIndex: number, pageSize: number) {
    this.isLoading = true;
    this.subjectService.getSubjectsByGradeId(gradeId, false, pageIndex, pageSize).subscribe({
      next: (data) => {
        let disciplinaInClassNotDefault = data.data!.data!.filter(
          (item) => !item.isDefault
        );
        this.isLoading = false;
        this.dataInput = disciplinaInClassNotDefault;
        this.pagination = {
          length: data.data!.totalItems ?? 0,
          pageSize: data.data!.limit ?? 10,
        };
        this.refreshTable();
      },
      error: (error) => {
        this.logService.error(`error in getUserByRole ${error}`);
      },
      complete: () => {
        this.logService.info(`Complete get user by role call`);
      },
    });
  }

  constructor(
    public dialogRef: MatDialogRef<DialogTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private logService: LoggingService,
    private subjectService: DisciplineService,
    private studentService: StudentService,
  ) {
    this.dataInput = data;
  }

  ngOnInit(): void {
    this.typeDialogTable = this.data.type;
    if (this.typeDialogTable === 1) {
      this.getStudents(1, 10);
    }
    if (this.typeDialogTable === 2) {
      this.getProfessors(1, 10);
    }
    if (this.typeDialogTable === 3) {
      this.getSubjects(this.data.gradeId, 1, 10);
    }
  }

  refreshTable() {
    this.showFilter = this.dataInput.length >= 7;
    this.typeDialogTable = this.data.type;
    // Set displayed columns
    this.displayedColumns = ["name"];
    if (this.data.showLogin) {
      this.displayedColumns.push("username");
    }
    switch (this.data.type) {
      case 1:
        this.columnHeaders = {
          name: "Aluno",
          username: "Matrícula",
          classname: "Turma",
        };
        this.noContentText = "Nenhum aluno cadastrado";
        this.noSearchText = "Nenhum aluno foi achado com o filtro estabelecido";
        this.displayedColumns = ["name"];
        if (this.data.showLogin) {
          this.displayedColumns.push("username");
          this.displayedColumns.push("classname");
        }
        break;
      case 2:
        this.columnHeaders = {
          name: "Professor cadastrado",
          especiality: "Especialização",
          username: "Login na plataforma"
        };
        this.noContentText = "Nenhum professor cadastrado";
        this.noSearchText =
          "Nenhum professor foi achado com o filtro estabelecido";
        this.displayedColumns = ["name", "especiality"];
        if (this.data.showLogin) {
          this.displayedColumns.push("username");
        }
        break;
      case 3:
        this.columnHeaders = {
          title: "Disciplina cadastrada",
        };
        this.noContentText = "Nenhuma disciplina cadastrada";
        this.noSearchText =
          "Nenhuma disciplina foi achada com o filtro estabelecido";
        this.displayedColumns = ["title"];
        break;
      default:
        break;
    }

    // Set table data
    this.dataSource = new MatTableDataSource(this.dataInput);
    this.selectionColumns = ["select", ...this.displayedColumns];
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  selectTeacher(event: any, row: any) {
    this.selection.clear();
    this.selection?.toggle(row)
  }

  applyFilter(event: Event): void {

    this.studentService.searchAluno(1, 10, (event.target as HTMLInputElement).value).subscribe({
      next: (data) => {
        this.update = data.data.data;
        this.update.forEach((student: any) => {
          if (student.created_at != student.updated_at) {
            student.status = true;
          }
          student.metadata?.forEach((meta: any) => {
            if (meta.name == 'invalid_data' && meta.value == 'true') {
              student.invalid_data = "true"
            }
          });
        });
        this.dataSource = data.data.data;
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });

  }

  onAddClick(): void {
    const selectedItems: User[] = this.selection.selected;
    this.dialogRef.close(selectedItems);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
