import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "../../../shared/models/subject.model";
import { Grade } from "../../../shared/models/grade.model";
import { TurmaService } from "../../../shared/services/turma.service";
import { GradeService } from "../../../shared/services/grade.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { ApiResponse } from "src/app/core/models/api-response";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { DisciplineBaseService } from "../../../shared/services/discipline-base.service";
import { SubjectBase } from "../../../shared/models/subject-base.model";

export interface baseSubject {
  id: number;
  subject: string;
}

const ELEMENT_DATA: baseSubject[] = [
  {
    id: 1,
    subject: "Linguagens e suas Tecnologias",
  },
  {
    id: 2,
    subject: "Matemática",
  },
  {
    id: 3,
    subject: "Ciências da Natureza",
  },
  {
    id: 4,
    subject: "Ciências Humanas e Sociais Aplicadas",
  },
];

@Component({
  selector: "app-create-disciplina",
  templateUrl: "./create-disciplina.component.html",
  styleUrls: ["./create-disciplina.component.sass"],
})
export class CreateDisciplinaComponent {
  createSubjectForm!: FormGroup;
  defaultSubject: Subject = {
    id: '0',
    title: "",
    created_at: "",
    updated_at: "",
    weekly_hours: 0,
    isDefault: true,
    school_id: '0',
    grade_id: '0',
    subject_base_id: '0',
  };
  baseSubject!: SubjectBase[];
  gradesBySchool!: Grade[];
  toggleStatus: boolean = false;
  isFormSynced: boolean = true;
  isLoadingSubmit: boolean = false;
  constructor(
    private gradeService: GradeService,
    private disciplinaService: DisciplineService,
    private disciplinaBaseService: DisciplineBaseService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    const schoolId = this.localStorageService.getDefaultSchool()[1];
    this.breadcrumbService.set("@create", "Criar Disciplina");
    this.disciplinaBaseService.getAllWoutPagination().subscribe({
      next: (apiData) => {
        this.baseSubject = apiData.data!;
      },
    });
    this.gradeService.getGrades().subscribe({
      next: (data) => {
        this.gradesBySchool = data;
      },
    });
    this.createSubjectForm = this.fb.group({
      selectorGrade: [null, Validators.required],
      disciplinaName: ["", Validators.required],
      selectorSubjectBase: [null, Validators.required],
      selectorSchoolId: [schoolId],
      weeklyHours: ["", Validators.required],
      selectorTeacher: [""],
      switchSpecial: [false, Validators.required],
    });
  }

  onToggleChange(event: any) {
    this.toggleStatus = event.checked;
  }

  onSubmit() {
    // Handle form submission logic here
    this.isLoadingSubmit = true;
    const newSubject: Subject = {
      title: this.createSubjectForm.get("disciplinaName")!.value,
      description: this.createSubjectForm.get("disciplinaName")!.value,
      isDefault: !this.toggleStatus,
      school_id: this.createSubjectForm.get("selectorSchoolId")!.value,
      grade_id: this.gradesBySchool.find(
        (grade) =>
          grade.id == this.createSubjectForm.get("selectorGrade")!.value
      )?.id!,
      weekly_hours: this.createSubjectForm.get("weeklyHours")!.value,
      subject_base_id: this.createSubjectForm.get("selectorSubjectBase")!.value,
    };
    // console.log(newSubject);
    this.disciplinaService.save(newSubject).subscribe({
      next: (apiResponse: ApiResponse<Subject>) => {
        // console.log(apiResponse?.data);
        this.isLoadingSubmit = false;
        this.router.navigate([".."], {
          relativeTo: this.route,
        });
        // this.router.navigate(["../edit", apiResponse.data?.id], {
        //   relativeTo: this.route,
        // });
      },
      error: (error) => {
        //console.log("error on creating disciplina:", error);
        this.isLoadingSubmit = false;
      },
      complete: () => {
        //console.log("complete create disciplina");
      },
    });
  }
}
