import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { Turma } from "../models/turma.model";
import { BehaviorSubject, Observable, catchError, map, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { PaginationData } from "src/app/core/models/pagination-data";
import { Grade } from "../models/grade.model";
import { User } from "src/app/core/models/user.model";

@Injectable({
  providedIn: "root",
})
export class TurmaService extends BaseService<Turma> {
  override baseUrl: string = `${this.baseUrl}/class`;
  private turmaSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public fetchTurmaByGrade(gradesId: Grade[]): void {
    this.getAll()
      .pipe(
        map((response: ApiResponse<PaginationData<Turma>>) => response.data!),
        map(
          (paginationData: PaginationData<Turma> | null) =>
            paginationData?.data || []
        ),
        map((turmas: Turma[]) =>
          turmas.filter((turma) =>
            gradesId.some((grade) => grade.id == turma.grade_id)
          )
        ),
        // tap((filteredTurmas) => console.log("Filtered Turmas:", filteredTurmas))
      )
      .subscribe({
        next: (turmasByGrades) => {
          this.turmaSubject.next(turmasByGrades);
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento das turmas: ", error);
        },
        complete: () => {
          console.log("fetch turmas complete");
        },
      });
  }

  public getTurmas(): Observable<any> {
    return this.turmaSubject.asObservable();
  }

  public addStudent(studentClassRelation: any): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}/add-students`;
    return this.api
      .post<ApiResponse<any>>(url, studentClassRelation)
      .pipe(
        tap((_) => {}), catchError(this.handleError("add Student to Class", "Esse aluno já está cadastrado em uma turma."))
      );
  }

  public removeStudent(
    studentClassRelationId: number
  ): Observable<ApiResponse<any>> {
    const url = `${this.baseUrl}/remove-student/${studentClassRelationId}`;
    return this.api.delete<ApiResponse<any>>(url, this.httpOptions).pipe(
      tap((_) => {
        this.log(
          `deleted studentClassRelationId id= ${studentClassRelationId}`
        );
      }),
      catchError(this.handleError("deleteEntity"))
    );
  }

  public createRelationWithStudent(student: User[], turmaId: string): any {
    const user_ids = student.map((student) => student.id);
    return { user_ids, class_id: turmaId };
  }

  public getTurmasPagination(page: number, limit:number): Observable<any> {
    const url = `${this.baseUrl}?limit=${limit}&page=${page}&countSubjects=true`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log(`fetched Entitys`)),
      catchError(this.handleError(`getAll`))
    );
  }

  public getMyClassesPagination(page: number, limit:number): Observable<any> {
    const url = `${this.baseUrl}?limit=${limit}&page=${page}&byTeacher=true`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log(`fetched Entitys`)),
      catchError(this.handleError(`getAll`))
    );
  }
  
  public getTurmaWithSubjectsAndTeacher(classId: number){
    const url = `${this.baseUrl}/${classId}?withSubjects=true`;
    return this.api.get<ApiResponse<any>>(url).pipe(
      tap((_) => this.log(`fetched Entity id=${classId} with Subjects`)),
      catchError(this.handleError(`getOne id=${classId} with Subjects`))
    );
  }
}
